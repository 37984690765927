<template>
	<div class="list-tool-bar">
		<div></div>
		<div class="search">
			<div class="search_item">
				<el-select
          v-model="searchData.created_by"
          filterable
          @change="doSearch"
          clearable
          :placeholder="t('system.user')">
					<el-option
            v-for="(item, index) in userList"
            :key="item.id"
            :label="item.label"
            :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search_item search_item_big">
				<custem-time ref="timeRef" @setTime="searchDate" />
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted, inject } from 'vue'
import { getUserList } from '@/utils/common'

const t = inject('t')
const emit = defineEmits(['getList'])
const searchData = ref({
	page: 1
})

const userList = ref([])
onMounted( async () => {
	userList.value = await getUserList()
})

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (searchData.value[key] === '') {
			delete searchData.value[key]
		}
	}
	emit('getList', searchData.value)
}

// 时间区间筛选数据处理
const searchDate = (data) => {
	if (data) {
		searchData.value.created_at_start = data[0]
		searchData.value.created_at_end = data[1]
	} else {
		searchData.value.created_at_start = ''
		searchData.value.created_at_end = ''
	}
	doSearch()
}
</script>
